<template>
  <LoadingPage v-if="loaded" />
  <div v-else>
    <h5 class="heading-4 ml-3 mb-1">
      {{ $t("settingsPage.title") }}
    </h5>

    <i18n path="settingsPage.subtitle" tag="p" class="ml-3">
      <template #link>
        <router-link
          class="link-1"
          to="/notifications/tracking-rule-notifications"
        >
          {{ $t("trackingRuleNotificationsPage.title") }}
        </router-link>
      </template>
    </i18n>

    <div class="grid gap-4">
      <SendEmail :settings="settings" />
      <div class="grid grid-cols-2 gap-4">
        <SendSms :settings="settings" />
        <SendWhatsapp :settings="settings" />
      </div>
      <SendWebhook :webhook="settings.seller_webhook" />
    </div>
  </div>
</template>

<script>
import NotificationSettingsService from "../../services/NotificationSettingsService";
import LoadingPage from "@/components/loadings/LoadingPage.vue";
import SendEmail from "./SendEmail.vue";
import SendSms from "./SendSms.vue";
import SendWhatsapp from "./SendWhatsapp.vue";
import SendWebhook from "./SendWebhook.vue";
const notificationSettingsService = new NotificationSettingsService();

export default {
  name: "Settings",
  components: {
    LoadingPage,
    SendEmail,
    SendSms,
    SendWhatsapp,
    SendWebhook,
  },
  data: () => {
    return {
      settings: null,
      loaded: true,
    };
  },
  beforeMount() {
    notificationSettingsService.getSettings().then((response) => {
      this.settings = response.data.data;
      this.loaded = false;
    });
  },
};
</script>

<style lang="scss"></style>
