<template>
  <div>
    <ModalHeader>
      <ModalTitle>
        {{ $t("settingsPage.textResendVerificationEmail") }}
      </ModalTitle>
    </ModalHeader>

    <p class="body-4" v-if="notificationEmail">
      {{ $t("settingsPage.textYouWillSendVerificationEmail") }}
      {{ notificationEmail }}
    </p>

    <ModalFooter class="d-flex justify-content-end gap-2">
      <ModalClose />
      <NbButton
        :disabled="loading"
        @click="resendVerificationEmail()"
        class="d-flex align-items-center gap-2"
      >
        <Spinner v-if="loading" />
        {{ $t("resend") }}
      </NbButton>
    </ModalFooter>
  </div>
</template>

<script>
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";
import ModalClose from "@/components/modal/ModalClose.vue";
import Spinner from "@/components/Spinner.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NotificationSettingsService from "@/services/NotificationSettingsService";

const notificationSettingsService = new NotificationSettingsService();

export default {
  components: {
    ModalHeader,
    ModalTitle,
    ModalFooter,
    ModalClose,
    NbButton,
    Spinner,
  },
  props: {
    notificationEmail: {
      type: Boolean,
      required: true,
    },
  },
  inject: ["context"],
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async resendVerificationEmail() {
      try {
        this.loading = true;
        await notificationSettingsService.resendVerificationEmail();
        this.$helpers.toast(
          this.$t("settingsPage.successfullyResentEmailVerification"),

          "success"
        );
        this.context?.close();
      } catch (error) {
        this.$helpers.toast(
          error.response.data.messages || "Error, try later",
          "danger"
        );
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
