<template>
  <div class="send-email">
    <NbCard :title="$t('settingsPage.sendEmail')" id="email">
      <template #body>
        <div class="info">
          <small>{{ $t("settingsPage.emailConfirmed") }}</small>
          <span
            v-if="
              typeof settings?.sender_signature?.[0]?.Confirmed === 'boolean'
            "
          >
            <IsCheckedIcon
              v-if="settings.sender_signature[0].Confirmed"
              checked
            />

            <div v-else>
              <NbButton
                v-if="notification_email"
                variant="tertiary"
                @click="showModalResendVerificationEmail()"
                class="d-flex gap-2 align-items-center"
              >
                <NbIcon icon="alert-triangle" class="text-warning" :size="18" />
                {{ $t("settingsPage.textResendVerificationEmail") }}
              </NbButton>
              <span v-else>-</span>
            </div>
          </span>
          <span v-else>-</span>
        </div>
        <div class="info">
          <small>{{ $t("settingsPage.domainVerified") }}</small>
          <span
            v-if="
              typeof settings?.sender_signature?.[0]?.DKIMVerified === 'boolean'
            "
          >
            <IsCheckedIcon
              v-if="settings.sender_signature[0].DKIMVerified"
              checked
            />
            <div v-if="notification_email">
              <NbButton
                variant="tertiary"
                @click="showModalVerifyDomain()"
                class="d-flex gap-2 align-items-center"
              >
                <NbIcon icon="alert-triangle" class="text-warning" :size="18" />
                {{ $t("settingsPage.howtoVerifyDomain") }}
              </NbButton>
            </div>
            <span v-else>-</span>
          </span>
          <span v-else>-</span>
        </div>
        <div class="info">
          <small>{{ $t("settingsPage.textSendFromEmail") }}</small>
          <span
            v-if="settings?.notification_email"
            class="d-flex gap-2 align-items-center"
          >
            {{ settings?.notification_email }}
            <NbButton @click="showEditModal()" variant="tertiary">
              <NbIcon icon="edit" />
              {{ $t("edit") }}
            </NbButton>
          </span>
          <span v-else>
            {{ $t("settingsPage.noEmailConfigured") }}
            <NbButton @click="showEditModal()" variant="tertiary">
              <NbIcon icon="edit" />
              {{ $t("settingsPage.addEmail") }}
            </NbButton>
          </span>
        </div>
      </template>
    </NbCard>

    <Modal v-model="isResendVerificationEmailModalOpen">
      <ResendVerificationEmail :notficationEmail="notification_email" />
    </Modal>

    <Modal v-model="isVerifyDomainModalOpen">
      <VerifyDomain
        :notficationEmail="notification_email"
        :senderSignature="settings.sender_signature || []"
      />
    </Modal>

    <Modal v-model="isEditEmailModalOpen">
      <ModalHeader>
        <ModalTitle>
          {{ $t("settingsPage.editDomain") }}
        </ModalTitle>
      </ModalHeader>

      <div>
        <NbTextInput
          id="notification_email"
          v-model="notification_email"
          :name="`${$t('settingsPage.notificationEmail')}
        (${$t('settingsPage.noPublicEmail')})`"
          placeholder="Email..."
          required
        />
      </div>

      <ModalFooter class="d-flex justify-content-end gap-2">
        <ModalClose />
        <NbButton
          @click="updateEmail()"
          :disabled="email_loader"
          class="d-flex gap-2 align-items-center"
        >
          <Spinner v-if="email_loader" />
          {{ !email_loader ? $t("update") : $t("uploading") }}
        </NbButton>
      </ModalFooter>
    </Modal>
  </div>
</template>

<script>
import NbCard from "@/components/cards/NbCard.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbIcon from "@/components/icons/NbIcon.vue";
import IsCheckedIcon from "@/components/IsCheckedIcon.vue";
import Spinner from "@/components/Spinner.vue";
import Modal from "@/components/modal/Modal.vue";
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";
import ModalClose from "@/components/modal/ModalClose.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";

import NotificationSettingsService from "@/services/NotificationSettingsService";
import ResendVerificationEmail from "./ResendVerificationEmail.vue";
import VerifyDomain from "./VerifyDomain.vue";

const notificationSettingsService = new NotificationSettingsService();

export default {
  name: "SendEmail",
  components: {
    NbCard,
    NbButton,
    NbIcon,
    IsCheckedIcon,
    Spinner,
    Modal,
    ModalHeader,
    ModalTitle,
    ModalFooter,
    ModalClose,
    NbTextInput,
    ResendVerificationEmail,
    VerifyDomain,
  },
  props: {
    settings: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      notification_email: this.settings.notification_email || "",
      email_loader: false,
      isEditEmailModalOpen: false,
      isResendVerificationEmailModalOpen: false,
      isVerifyDomainModalOpen: false,
    };
  },
  methods: {
    showModalVerifyDomain() {
      this.isVerifyDomainModalOpen = true;
    },
    showModalResendVerificationEmail() {
      this.isResendVerificationEmailModalOpen = true;
    },
    showEditModal() {
      this.isEditEmailModalOpen = true;
    },
    async updateEmail() {
      try {
        this.email_loader = true;

        await notificationSettingsService.updateEmail({
          notification_email: this.notification_email,
        });

        this.$helpers.toast(
          this.$t("settingsPage.successfullyUpdatedSetting"),
          "success"
        );
        this.isEditEmailModalOpen = false;
      } catch (error) {
        this.$helpers.toast(
          error.response.data.messages.notification_email[0] ||
            this.$t("settingsPage.emailFormatIsInvalidOrNotAllowed"),
          "danger"
        );
      } finally {
        this.email_loader = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.send-email {
  .info {
    display: flex;
    flex-direction: column;
    small {
      font-weight: 700;
      font-size: 0.75rem;
    }
    span {
      font-size: 0.875rem;
      line-height: 1.2;
    }
    & + .info {
      margin-top: 1rem;
    }
  }
}
</style>
