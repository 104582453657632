<template>
  <div>
    <ModalHeader>
      <ModalTitle>
        {{ $t("settingsPage.howtoVerifyDomain") }}
      </ModalTitle>
    </ModalHeader>

    <div>
      <p class="body-4">
        {{ $t("settingsPage.textHowtoVerifyDomain1") }}
        <b>{{ senderSignature?.[0]?.domain }}</b>
        {{ $t("settingsPage.textHowtoVerifyDomain2") }}
      </p>
      <div class="content my-4">
        <div class="info">
          <small>{{ $t("settingsPage.hostname") }}</small>
          <span>{{ senderSignature?.[0]?.Hostname || "-" }}</span>
        </div>

        <div class="info">
          <small>{{ $t("settingsPage.type") }}</small>
          <span>{{ senderSignature?.[0]?.Type || "-" }}</span>
        </div>

        <div class="info">
          <small>{{ $t("settingsPage.value") }}</small>
          <span>{{ senderSignature?.[0]?.Value || "-" }}</span>
        </div>
      </div>
      <p class="body-4">{{ $t("settingsPage.textHowtoVerifyDomain3") }}</p>
    </div>

    <ModalFooter>
      <ModalClose class="d-flex ml-auto" />
    </ModalFooter>
  </div>
</template>

<script>
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";
import ModalClose from "@/components/modal/ModalClose.vue";

export default {
  components: {
    ModalHeader,
    ModalTitle,
    ModalFooter,
    ModalClose,
  },
  props: {
    notificationEmail: {
      type: Boolean,
      required: true,
    },
    senderSignature: {
      type: Array,
      default: () => [],
    },
  },
  inject: ["context"],
  data() {
    return {
      loading: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.content {
  .info {
    small {
      font-size: 0.75rem;
      font-weight: 700;
    }
    span {
      font-size: 0.875rem;
    }
  }
  background-color: var(--gray-05);
  border-radius: 4px;
  padding: 1rem;
}
</style>
