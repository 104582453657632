<template>
  <div class="send-webhook">
    <NbCard
      id="webhook"
      :title="$t('settingsPage.sendWebhook')"
      :subTitle="$t('settingsPage.textSendWebhook')"
    >
      <template #body>
        <div v-if="hasWebhook" class="mb-4">
          <h3 class="heading-3">
            <span class="text-primary">{{ webhookData.method || "-" }}</span> -
            <small>{{ webhookData.endpoint }}</small>
          </h3>

          <div class="mt-4">
            <h3 class="heading-3">{{ $t("settingsPage.headers") }}</h3>
            <span v-if="!hasHeaders">-</span>
            <table>
              <tbody>
                <tr
                  v-for="(value, key, index) in webhookData.headers"
                  :key="index"
                >
                  <td>{{ key }}</td>
                  <td class="header-value">{{ value }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <NbButton @click="showWebhookModal()">
          {{ hasWebhook ? $t("update") : $t("create") }}
        </NbButton>
      </template>
    </NbCard>

    <Modal v-model="isWebhookModalOpen">
      <WebhookData :webhook="webhookData" @updated="webhookData = $event" />
    </Modal>
  </div>
</template>

<script>
import NbCard from "@/components/cards/NbCard.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import Modal from "@/components/modal/Modal.vue";

import WebhookData from "./WebhookData.vue";

export default {
  name: "SendSms",
  components: {
    NbCard,
    NbButton,
    WebhookData,
    Modal,
  },
  props: {
    webhook: {
      type: [Object, null],
      default: null,
    },
  },
  data() {
    return {
      isWebhookModalOpen: false,
      loading: false,
      webhookData: {
        endpoint: "",
        method: "",
        headers: {},
      },
    };
  },
  computed: {
    hasWebhook() {
      return this.webhookData.endpoint && this.webhookData.method;
    },
    hasHeaders() {
      return !!Object.values(this.webhookData?.headers)?.length;
    },
  },
  methods: {
    showWebhookModal() {
      this.isWebhookModalOpen = true;
    },
  },
  created() {
    const parser = {
      endpoint: this?.webhook?.url || this.webhook?.endpoint || "",
      method: this.webhook?.method || "",
      headers: this.webhook?.headers || {},
    };
    if (this.webhook?.header) {
      parser.headers[this.webhook?.header] = this.webhook?.key || "";
    }

    this.webhookData = parser;
  },
};
</script>

<style lang="scss" scoped>
.send-webhook {
  .info {
    display: flex;
    flex-direction: column;
    small {
      font-weight: 700;
      font-size: 0.75rem;
    }
    span {
      font-size: 0.875rem;
    }
  }

  table {
    width: 100%;
    tr {
      td {
        border-bottom: 1px solid var(--gray-10);
        border-radius: 8px;
        padding: 1rem 0;
        &:first-child {
          font-weight: bold;
        }
        &.header-value {
          color: var(--gray-60);
        }
      }
    }
    &:first-child {
      td {
        border-top: 1px solid var(--gray-10);
      }
    }
  }
}
</style>
