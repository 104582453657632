<template>
  <div class="webhook-data">
    <ModalHeader>
      <ModalTitle>
        {{ $t("settingsPage.addWebhookUrl") }}
      </ModalTitle>
    </ModalHeader>

    <form @submit.prevent="updateWebhook()" class="d-flex flex-column gap-4">
      <NbRadio
        v-model="webhookData.method"
        id="method"
        :name="$t('settingsPage.method')"
        :options="methodOptions"
        required
      />
      <NbTextInput
        id="endpoint"
        v-model="webhookData.endpoint"
        :name="$t('settingsPage.endpoint')"
        placeholder="https://example.com/hook"
        required
      />
    </form>

    <div class="d-flex gap-4 align-items-end my-4">
      <NbTextInput
        id="header.key"
        v-model="header.key"
        :name="$t('settingsPage.key')"
        class="flex-grow-1"
      />
      <NbTextInput
        id="header.value"
        v-model="header.value"
        :name="$t('settingsPage.value')"
        class="flex-grow-1"
      />
      <NbButton
        type="button"
        @click="appendNewHeader()"
        :disabled="!headerIsValid"
      >
        <NbIcon icon="plus" :size="18" />
      </NbButton>
    </div>

    <table>
      <tbody>
        <tr v-for="(value, key, index) in webhookData.headers" :key="index">
          <td>{{ key }}</td>
          <td class="header-value">{{ value }}</td>
          <td>
            <NbButton variant="tertiary" @click="removeHeaderByKey(key)">
              <NbIcon icon="trash-2" :size="18" />
            </NbButton>
          </td>
        </tr>
      </tbody>
    </table>

    <ModalFooter class="d-flex justify-content-end gap-2">
      <ModalClose />

      <NbButton
        @click="updateWebhook()"
        :disabled="!formIsValid || loading"
        class="d-flex align-items-center gap-2"
      >
        <Spinner v-if="loading" />
        {{ $t("save") }}
      </NbButton>
    </ModalFooter>
  </div>
</template>

<script>
import Spinner from "@/components/Spinner.vue";
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";
import ModalClose from "@/components/modal/ModalClose.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbRadio from "@/components/buttons/NbRadio.vue";
import NbIcon from "@/components/icons/NbIcon.vue";
import NbButton from "@/components/buttons/NbButton.vue";

import NotificationSettingsService from "@/services/NotificationSettingsService";
const notificationSettingsService = new NotificationSettingsService();

export default {
  name: "WebhookData",
  components: {
    Spinner,
    ModalHeader,
    ModalTitle,
    ModalFooter,
    ModalClose,
    NbTextInput,
    NbRadio,
    NbIcon,
    NbButton,
  },
  props: {
    webhook: {
      type: [Object, null],
      default: null,
    },
  },
  inject: ["context"],
  data() {
    return {
      loading: false,
      availableMethods: ["POST", "PUT"],
      webhookData: {
        endpoint: "",
        method: "",
        headers: {},
      },
      header: {
        key: "",
        value: "",
      },
    };
  },
  computed: {
    formIsValid() {
      return this.webhookData.endpoint.trim() && this.webhookData.method;
    },
    headerIsValid() {
      return this.header.key.trim() && this.header.value.trim();
    },
    methodOptions() {
      return this.availableMethods.map((method) => {
        return {
          text: method,
          value: method,
        };
      });
    },
  },
  methods: {
    appendNewHeader() {
      this.webhookData.headers[this.header.key] = this.header.value;
      this.header = {
        key: "",
        value: "",
      };
    },
    removeHeaderByKey(key) {
      this.$delete(this.webhookData.headers, key);
    },
    async updateWebhook() {
      try {
        this.loading = true;
        await notificationSettingsService.updateWebHook({
          webhook: this.webhookData,
        });
        this.$emit("updated", this.webhookData);
        this.context?.close();
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    this.webhookData = JSON.parse(JSON.stringify(this.webhook));
  },
};
</script>

<style lang="scss" scoped>
.webhook-data {
  table {
    width: 100%;
    tr {
      td {
        border-bottom: 1px solid var(--gray-10);
        border-radius: 8px;
        padding: 1rem 0;
        &:first-child {
          font-weight: bold;
        }
        &.header-value {
          color: var(--gray-60);
        }
      }
    }
    &:first-child {
      td {
        border-top: 1px solid var(--gray-10);
      }
    }
  }
}
</style>
