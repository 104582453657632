<template>
  <div>
    <NbCard id="sms">
      <template #header>
        <div class="d-flex align-items-center gap-2">
          <h3 class="heading-3 m-0">
            {{ $t("settingsPage.sendWhatsapp") }}
          </h3>

          <NbBadge v-if="send_whatsapp" :text="$t('active')" type="success" />
          <NbBadge v-else :text="$t('inactive')" type="error" />
        </div>
      </template>
      <template #body>
        <p class="body-3">
          {{ $t("settingsPage.textSendWhatsapp") }}
        </p>

        <p class="body-3">
          {{ $t("settingsPage.sendFromYourCompanyPhoneNumber") }}
        </p>

        <p class="body-3">
          {{ $t("settingsPage.whatsappCostsWillBeCharged") }}
        </p>

        <NbButton @click="showModal()" class="mr-auto mt-4">
          {{ send_whatsapp ? $t("disable") : $t("enable") }}
        </NbButton>
      </template>
    </NbCard>

    <Modal v-model="isModalOpen">
      <ModalHeader>
        <ModalTitle>
          {{ $t("confirmation") }}
        </ModalTitle>
      </ModalHeader>

      <div v-if="send_whatsapp">
        <p class="body-3">
          <i18n path="settingsPage.disableWhatsappText">
            <template #link>
              <router-link
                class="link-1"
                to="/notifications/tracking-rule-notifications"
              >
                {{ $t("trackingRuleNotificationsPage.title") }}
              </router-link>
            </template>
          </i18n>
        </p>
      </div>

      <div v-else>
        <p class="body-3">
          {{ $t("settingsPage.enableWhatsappText") }}
        </p>
        <p class="body-3">
          {{ $t("settingsPage.pleaseConfirmAdditionalCosts") }}
        </p>
      </div>

      <ModalFooter class="d-flex justify-content-end gap-2">
        <ModalClose />
        <NbButton
          v-if="whatsapp_loader"
          disabled
          class="d-flex gap-2 align-items-center"
        >
          <Spinner />
          {{
            send_whatsapp
              ? $t("settingsPage.disabling")
              : $t("settingsPage.enabling")
          }}
        </NbButton>
        <NbButton v-else @click="updateWhatsApp()">
          {{ send_whatsapp ? $t("disable") : $t("enable") }}
        </NbButton>
      </ModalFooter>
    </Modal>
  </div>
</template>

<script>
import NbCard from "@/components/cards/NbCard.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbBadge from "@/components/alerts/NbBadge.vue";
import Spinner from "@/components/Spinner.vue";
import Modal from "@/components/modal/Modal.vue";
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";
import ModalClose from "@/components/modal/ModalClose.vue";

import NotificationSettingsService from "@/services/NotificationSettingsService";

const notificationSettingsService = new NotificationSettingsService();

export default {
  name: "SendSms",
  components: {
    NbCard,
    NbButton,
    NbBadge,
    Spinner,
    Modal,
    ModalHeader,
    ModalTitle,
    ModalFooter,
    ModalClose,
  },
  props: {
    settings: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      send_whatsapp: this.settings.send_whatsapp,
      whatsapp_loader: false,
      isModalOpen: false,
    };
  },
  methods: {
    showModal() {
      this.isModalOpen = true;
    },
    async updateWhatsApp() {
      try {
        this.whatsapp_loader = true;
        await notificationSettingsService.updateWhatsApp({
          send_whatsapp: !this.send_whatsapp,
        });
        this.send_whatsapp = !this.send_whatsapp;
        this.isModalOpen = false;
      } finally {
        this.whatsapp_loader = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
